define("itarp-opportunity-designer/adapters/opportunity", ["exports", "itarp-auth-addon/adapters/ad-token-data", "@ember/string", "@ember/array"], function (_exports, _adTokenData, _string, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class OpportunityAdapter extends _adTokenData.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "namespace", '/opportunity/data_service');
    }

    urlForUpdateRecord(id, modelName, snapshot) {
      const action = snapshot.record.actionFlag;

      if (action) {
        const endpoints = {
          setOnHold: 'hold',
          approve: 'approve',
          close: 'close',
          decline: 'decline'
        };
        const endpoint = `${this.namespace}/opportunities/${id}/${endpoints[action]}`;
        delete snapshot.record.actionFlag;
        return endpoint;
      }

      return super.urlForUpdateRecord(...arguments);
    }

    urlForCreateRecord(modelName, snapshot) {
      const cloneId = snapshot.record.cloneId;

      if (cloneId) {
        return `${this.namespace}/opportunities/${cloneId}/clone`;
      }

      return super.urlForCreateRecord(...arguments);
    }

    urlForQuery(query) {
      var _query$adapterOptions, _query$adapterOptions2;

      if ((_query$adapterOptions = query.adapterOptions) !== null && _query$adapterOptions !== void 0 && _query$adapterOptions.myOpportunities) {
        delete query.adapterOptions.myOpportunities;
        return `${this.namespace}/opportunities/me`;
      }

      if ((_query$adapterOptions2 = query.adapterOptions) !== null && _query$adapterOptions2 !== void 0 && _query$adapterOptions2.publicOpportunities) {
        delete query.adapterOptions.publicOpportunities;
        return `/opportunity/external/opportunities/experfy`;
      }

      return super.urlForQuery(...arguments);
    }

    formatErrors(payload) {
      const errors = payload === null || payload === void 0 ? void 0 : payload.errors;
      const result = (0, _array.A)([]);

      if (errors) {
        const resourceName = Object.keys(errors)[0];
        const resourceErrors = errors[resourceName];

        if (typeof resourceErrors === 'string') {
          result.push(resourceErrors);
          return result;
        }

        for (const field in resourceErrors) {
          const fieldErrors = resourceErrors[field];

          if (fieldErrors) {
            for (let error of fieldErrors) {
              error = error.replace(field, '');
              const source = {
                pointer: `/data/attributes/${field}`
              };
              const formattedField = (0, _string.capitalize)(field.replaceAll('_', ' '));
              let detail = null;

              if (field === 'ideal_candidate_location') {
                detail = 'Ideal candidate location is required when "I prefer candidates from certain locations" is checked.';
              } else if (field === 'skills' || field === 'tools') {
                detail = `${error.replaceAll('_', ' ')}`;
              } else {
                detail = `${formattedField} ${error.replaceAll('_', ' ')}`;
              }

              result.pushObject({
                source,
                detail
              });
            }
          }
        }
      }

      return result;
    }

  }

  _exports.default = OpportunityAdapter;
});