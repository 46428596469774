define("itarp-business-application/controllers/my-favorites", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _controller, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MyFavoritesController = (_dec = (0, _service.inject)('store'), (_class = class MyFavoritesController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      _initializerDefineProperty(this, "layout", _descriptor3, this);

      _defineProperty(this, "queryParams", ['opportunityId']);

      _initializerDefineProperty(this, "opportunityId", _descriptor4, this);

      _initializerDefineProperty(this, "questions", _descriptor5, this);
    }

    deleteFavorite(opportunity) {
      const adapter = this.store.adapterFor('user-favorite');
      const endpoint = `${adapter.namespace}/favorites`;
      const method = 'DELETE';
      const headers = adapter.headers;
      fetch(endpoint, {
        method,
        headers,
        body: JSON.stringify({
          ids: [opportunity.id],
          resource_type: 'opportunity'
        })
      }).then(async response => await response.json()).then(data => {
        (0, _object.set)(this, 'favorites', this.favorites.filter(f => f.id !== opportunity.id));
        let message = 'Opportunity removed from favorites';
        this.toast.show(message);

        if (this.favorites.length) {
          (0, _object.set)(this, 'selectedProject', this.favorites[0]);
          (0, _object.set)(this, 'opportunityId', this.favorites[0].id);
        } else {
          (0, _object.set)(this, 'noRecords', true);
          (0, _object.set)(this, 'selectedProject', null);
          (0, _object.set)(this, 'opportunityId', null);
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "layout", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "opportunityId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "questions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteFavorite", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteFavorite"), _class.prototype)), _class));
  _exports.default = MyFavoritesController;
});